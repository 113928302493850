/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /* Visual */
        var $swiper_visual = new Swiper('#swiper-visual', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            direction: 'horizontal',
            speed: 1000,
            autoplay: 5000,
            loop: true,
            parallax: true
        });

        /* Companies */
        var $swiper_logo = new Swiper('#swiper-logo', {
            slidesPerView: 4,
            spaceBetween: 30,
            direction: 'horizontal',
            speed: 1000,
            autoplay: 2000,
            loop: true,
            parallax: true,
            breakpoints: {
            480: {
              slidesPerView: 1
            },
            768: {
              slidesPerView: 2
            },
            992: {
              slidesPerView: 4
            }
          }
        });

        /* Galleries */
        var $swiper_gallery = new Swiper('#swiper-gallery', {
          slidesPerView: 3,
          spaceBetween: 30,
          pagination: '.swiper-pagination',
          paginationClickable: true,
          direction: 'horizontal',
          speed: 1000,
          // autoplay: 5000,
          loop: false,
          breakpoints: {
            480: {
              slidesPerView: 1
            },
            768: {
              slidesPerView: 2
            },
            992: {
              slidesPerView: 3
            }
          }
        });

        _navToggle();
        _navHover();
        _searchToggle();
        _initTabs();
        _ledenFilter();
        _quotesCycle();
  
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  var _ledenFilter = function() {

    $('#leden-branche').change(function() {
      this.form.submit();
    });
  };

  var _quotesCycle = function() {

    $('.quote-content').cycle({
      'slides' : '> blockquote',
      'speed' : 100,
      'auto-height' : 'calc'
    });
  };

  var _initTabs = function() {

    $('.tabs li').click(function () {

      $(this).parent('ul').children('li').removeClass('current');
      $(this).parent('ul').siblings('.tab-content').removeClass('current');
      $(this).addClass('current');

      // Show content of tab
      var $tab_id = $(this).attr('data-tab');
      $(this).parent('ul').siblings("#" + $tab_id).addClass('current');
    });
  };

  var _navToggle = function() {

    $('.nav-toggle').click(function() {

      $toggle = $(this);
      $overlay = $('#nav-overlay');
      $search = $('.search-toggle');

      $toggle.toggleClass('active');
      $overlay.toggleClass('active');
      $search.toggleClass('hide');

      $('body').toggleClass('no-scroll');
    });
  };

  var _navHover = function() {

    $('#menu-hoofdmenu li').hover(function() {  
      if ( $('.sub-menu').is(":visible") ) {
        $('.banner').css('height', '180px');
      }
    });

    $('#menu-hoofdmenu li').hover(function() {  
      $('#menu-hoofdmenu li .sub-menu').css('display', 'none');
      $(this).children('.sub-menu').css('display','block');
    });
  };

  var _searchToggle = function() {

    $('.search-toggle').click(function() {

      $toggle =  $(this);
      $overlay = $('#block-search');

      $toggle.toggleClass('active');
      $overlay.toggleClass('active');
      
      // Focus on keyword
      setTimeout(function() { 
        $('#search-keyword').focus();
      }, 100);
    });
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  

})(jQuery); // Fully reference jQuery after this point.
